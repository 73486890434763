var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import AsyncStatus from "../../utils/async";
import resetPassword from "../../api/mutations/resetPassword";
import mixins from "vue-typed-mixins";
import NotificationsMixin from "../ui/NotificationsMixin";
import gql from "graphql-tag";
import PasswordForm from "../ui/PasswordForm.vue";
export default mixins(NotificationsMixin).extend({
    components: {
        PasswordForm: PasswordForm,
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    apollo: {
        verifyResetPassword: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query ResetPasswordQuery($token: String!, $encodedUserId: String!) {\n          verifyResetPassword(token: $token, encodedUserId: $encodedUserId)\n        }\n      "], ["\n        query ResetPasswordQuery($token: String!, $encodedUserId: String!) {\n          verifyResetPassword(token: $token, encodedUserId: $encodedUserId)\n        }\n      "]))),
            variables: function () {
                return {
                    token: this.$route.params.token,
                    encodedUserId: this.$route.params.userId,
                };
            },
        },
    },
    methods: {
        onSubmit: function (password) {
            var _this = this;
            return this.$apollo
                .mutate(resetPassword({
                token: this.$route.params.token,
                encodedUserId: this.$route.params.userId,
                password: password,
            }))
                .then(function () {
                return _this.displaySuccessMessage(_this.$t("Your password has been updated"));
            })
                .then(function () {
                _this.$router.push("/sign-in");
            });
        },
    },
});
var templateObject_1;
